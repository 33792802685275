import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home.vue'

import store from '@/store'
import { firebase } from '@/firebase'

/**
 * vue-router v3.1.0から、同一画面に遷移するとコンソールエラーが発生するようになった
 * push関数にエラーハンドリングを追加して、デフォルトの関数を上書きすることで対応
 * @see https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
 */
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

const originalReplace = Router.prototype.replace
Router.prototype.replace = function replace (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: { requiresAuth: true }
    },
    {
      path: '/login',
      name: 'login',
      component: loadComponent('Login.vue')
    },
    {
      path: '/signup',
      name: 'signup',
      component: loadComponent('Signup.vue')
    },
    {
      path: '/signup/user',
      name: 'signup_user',
      component: loadComponent('SignupUser.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/signup/card',
      name: 'signup_card',
      component: loadComponent('SignupCard.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/signup/done',
      name: 'signup_done',
      component: loadComponent('SignupDone.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/profile/edit',
      name: 'profile_edit',
      component: loadComponent('ProfileEdit.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/profile/:uid',
      name: 'profile',
      component: loadComponent('Profile.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/payment/card/change',
      name: 'payment_card_change',
      component: loadComponent('PaymentCardChange.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/menu',
      name: 'menu',
      component: loadComponent('Menu.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/cancel',
      name: 'cancel',
      component: loadComponent('Cancel.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/maintenance',
      name: 'maintenance',
      component: loadComponent('Maintenance.vue'),
      meta: { autoTransition: true }
    },
    {
      path: '/error',
      name: 'error',
      component: loadComponent('Error.vue'),
      meta: { autoTransition: true }
    },
    {
      path: '/notfound',
      name: 'notfound',
      component: loadComponent('NotFound.vue'),
      meta: { autoTransition: true }
    },
    {
      path: '**',
      redirect: { name: 'notfound' }
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

/**
 * viewsのファイルをロード
 * @param {String} name viewsのファイル名
 * @return {Object} 遅延ロードしたコンポーネント
 */
function loadComponent (name) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/views/${name}`)
}

/**
 * ページ遷移の分岐処理
 */
router.beforeEach((to, from, next) => {
  const autoTransition = to.matched.some(record => record.meta.autoTransition)
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  // 認証後の遷移先として現在のURLを保存する
  const redirectURL = store.getters.redirectPath
  if (!redirectURL) store.commit('setRedirectURL', to.path)

  // 判定用のデータ取得
  const auth = firebase.auth().currentUser
  const uid = auth ? auth.uid : null
  const user = uid ? store.getters['users/user'](uid) : null
  const exemption = uid ? store.getters['exemptions/exemption'] : null
  const card = store.getters['cards/card']
  const isAuthProcessing = store.getters.isAuthProcessing
  const payment = store.getters['payments/payment']
  const subscription = store.getters['subscriptions/subscription']

  // 遷移条件
  // ログイン済みかつユーザー情報があるが名前の登録がまだの場合ユーザー情報登録画面への遷移
  const isSignupUser = user && !user.name
  // ログイン済みかつユーザー情報が登録されており、例外ユーザーでなくカード情報が無い場合
  const isSignupCard = !exemption && !card
  // 決済が失敗している場合
  const isFailedPayment = (payment && payment.status === 'cardError') || (subscription && ['incomplete', 'incomplete_expired', 'past_due', 'unpaid'].includes(subscription.status))

  // 認証、ユーザー取得状況に応じてページ遷移
  // 初回アクセス時、onAuthの処理が終わる前にrouterが動くため、初回処理が終わるまで遷移をループさせる
  if (isAuthProcessing || autoTransition) {
    if (autoTransition) {
      next()
    } else {
      // Auth処理中の場合はパラメータ情報が失われないようstoreに保存し遷移をキャンセルさせる
      store.commit('setQueryParameter', location.search)
      next(false)
    }
  } else if (!uid) {
    // 未ログインの場合
    requiresAuth ? next({ name: 'login' }) : next()
  } else if (isSignupUser) {
    // ログイン済みかつユーザー情報未登録の場合
    to.name === 'signup_user' ? next() : next({ name: 'signup_user' })
  } else if (isSignupCard) {
    // ログイン済みかつ通常ユーザーでまだカードが未登録の場合
    to.name === 'signup_card' ? next() : next({ name: 'signup_card' })
  } else if (isFailedPayment) {
    // 決済が失敗している場合カード変更画面へ遷移させる
    to.name === 'payment_card_change' ? next() : next({ name: 'payment_card_change' })
  } else if (['signup_user', 'signup_card'].includes(to.name)) {
    // ログイン済みかつ加入済みで不要な画面に遷移しようとした場合ホームへ遷移させる
    next({ name: 'home' })
  } else {
    // ログイン済みかつ加入済み
    requiresAuth ? next() : next({ name: 'home' })
  }
})

/**
 * beforeEachで遷移が確定した後の処理
 */
router.beforeResolve((to, from, next) => {
  const isAuthProcessing = store.getters.isAuthProcessing

  // 遷移先と遷移元が異なればisProcessingをtrueにする
  if (!isAuthProcessing && to.name !== from.name) store.commit('setProcessing', true)
  next()
})

export default router
