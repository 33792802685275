import Vue from 'vue'
import Vuex from 'vuex'

import auth from '@/store/modules/auth'
import functions from '@/store/modules/functions'
import cloudstorage from '@/store/modules/cloudstorage'
import users from '@/store/modules/users'
import exemptions from '@/store/modules/exemptions'
import payments from '@/store/modules/payments'
import tokens from '@/store/modules/payments/tokens'
import cards from '@/store/modules/payments/cards'
import plans from '@/store/modules/payments/plans'
import subscriptions from '@/store/modules/payments/subscriptions'
import cancels from '@/store/modules/cancels'
import settingImages from '@/store/modules/settingImages'
import settingPrivileges from '@/store/modules/settingPrivileges'
import maintenances from './modules/maintenances'

Vue.use(Vuex)

const modules = { auth, functions, cloudstorage, users, exemptions, payments, tokens, cards, plans, subscriptions, cancels, settingImages, settingPrivileges, maintenances }

export default new Vuex.Store({
  modules: modules,
  state: {
    isProcessing: true,
    isSubmitting: false,
    isAuthProcessing: true,
    redirectPath: null,
    queryParameter: '',
    telop: {
      show: false,
      msg: '',
      type: ''
    }
  },
  getters: {
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Boolean} 処理中かどうか
     */
    isProcessing: state => state.isProcessing,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Boolean} フォーム等の送信中かどうか
     */
    isSubmitting: state => state.isSubmitting,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Boolean} Auth処理中かどうか
     */
    isAuthProcessing: state => state.isAuthProcessing,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {String} 遷移先のURL
     */
    redirectPath: state => state.redirectPath,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {String} 遷移先のURLのパラメータ
     */
    queryParameter: state => state.queryParameter,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Object} テロップの情報
     */
    telop: state => state.telop
  },
  mutations: {
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {Boolean} isAuthProcessing Auth処理中かどうか
     */
    setAuthProcessing: (state, isAuthProcessing) => {
      state.isAuthProcessing = isAuthProcessing
    },
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {Boolean} isProcessing 処理中かどうか
     */
    setProcessing: (state, isProcessing) => {
      state.isProcessing = isProcessing
      if (state.isSubmitting) state.isSubmitting = false
    },
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {Boolean} isSubmitting フォーム等の送信中かどうか
     */
    setSubmitting: (state, isSubmitting) => {
      state.isSubmitting = isSubmitting
    },
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {String} path アクセスされたURL
     */
    setRedirectURL: (state, path) => {
      state.redirectPath = path
    },
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {String} queryParameter アクセスされたURLのパラメータ
     */
    setQueryParameter: (state, queryParameter) => {
      state.queryParameter = queryParameter
    },
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {Object} telop テロップの情報
     * @param {Boolean} telop.show テロップ表示の有無
     * @param {String} telop.msg テロップの内容
     * @param {String} telop.type テロップの色 success, warning, error
     */
    setTelop: (state, telop) => {
      state.telop = telop
    }
  },
  actions: {
    /**
     * 全モジュールのstore情報をリセットする
     */
    resetState: ({ commit }) => {
      Object.keys(modules).forEach(key => {
        // stateにプロパティが存在するモジュールのresetStateを起動する
        if (Object.keys(modules[key].state).length !== 0) commit(key + '/resetState')
      })
    }
  }
})
