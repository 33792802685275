<template>
  <footer class="footer" v-if="isDisplay">
    <v-img class="footer__logo" :src="require('@/assets/img/logo.svg')" contain />
    <div class="footer__links">
      <div class="footer__links__item">
        <v-icon class="footer__links__item__icon">arrow_forward_ios</v-icon>
        <a class="footer__links__item__text" target="_blank"
           href="https://linkdesign.jp/">運営会社</a>
      </div>
      <div class="footer__links__item">
        <v-icon class="footer__links__item__icon">arrow_forward_ios</v-icon>
        <a class="footer__links__item__text" target="_blank"
           href="https://forms.gle/7AYLvEQuZ4JrBqUX8">お問い合わせ</a>
      </div>
      <div class="footer__links__item">
        <v-icon class="footer__links__item__icon">arrow_forward_ios</v-icon>
        <a class="footer__links__item__text" target="_blank"
           href="https://docs.google.com/document/d/16H6ufWJqm-S6TbqlVaE_CcQ4lvrGZsiqZnsOaIz3TCo/edit?usp=sharing">利用規約</a>
      </div>
      <div class="footer__links__item">
        <v-icon class="footer__links__item__icon">arrow_forward_ios</v-icon>
        <a class="footer__links__item__text" target="_blank"
           href="https://docs.google.com/document/d/1DSjrvcDadl2W0W78Wl8U0BNVwStZSWhdgAhZVr91O14/edit?usp=sharing">プライバシーポリシー</a>
      </div>
      <div class="footer__links__item">
        <v-icon class="footer__links__item__icon">arrow_forward_ios</v-icon>
        <a class="footer__links__item__text" target="_blank"
           href="https://docs.google.com/document/d/1rDfL5JYZrnUWidEmDjqCsX4zix4C0w-j66ms8h7JeWc/edit?usp=sharing">特定商取引法に基づく表記</a>
      </div>
    </div>
  </footer>
</template>

<script>
// フッターを表示させるページ名
const DISPLAY = [
  'login',
  'signup',
  'signup_card',
  'signup_user'
]

export default {
  computed: {
    /**
     * @return {Boolean} フッターを表示させるかどうか
     */
    isDisplay () {
      return DISPLAY.includes(this.$route.name)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.footer {
  z-index: $footer_zindex;
  width: 100vw;
  max-width: $max_width;
  min-height: $footer_height;
  padding: 10px $padding_width 15px;
  background-color: $gray_lighten_color;
  &__logo {
    height: 30px;
    margin: 0 auto 7px;
  }
  &__links {
    display: flex;
    flex-wrap: wrap;
    max-width: 321px;
    margin: 0 auto;
    &__item {
      display: flex;
      align-items: center;
      width: 50%;
      margin-top: 10px;
      &__icon {
        width: 10px;
        height: 10px;
        &.v-icon {
          font-size: 1rem;
        }
      }
      &__text {
        padding-right: 10px;
        padding-left: 5px;
        font-size: 1rem;
        line-height: 1.2rem;
      }
    }
  }
}
</style>
