<template>
  <div class="home" v-if="!isProcessing">
    <div class="home__member">
      <h3 class="home__member__text">LDWの仲間たち</h3>
      <p class="home__member__number">{{ Object.keys(users).length }}<span class="home__member__number__unit">人</span></p>
    </div>
    <div class="home__users">
      <home-user class="home__users__item" v-for="uid in Object.keys(users)" :key="uid" :uid="uid" :user="users[uid]" />
    </div>
  </div>
</template>

<script>
import HomeUser from '@/components/home/user'

export default {
  components: { HomeUser },
  name: 'home',
  mounted () {
    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    /**
     * @return {Object} LDWのユーザー一覧
     */
    users () {
      return this.$store.getters['users/users']
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';

.home {
  width: 100vw;
  max-width: $max_width;
  min-height: calc(100vh - #{$header_height});
  padding: $padding_height $padding_width;
  margin: $header_height auto 0;
  &__member {
    text-align: center;
    &__text {
      font-size: 1.2rem;
      font-weight: normal;
    }
    &__number {
      margin: 5px 0 0;
      font-size: 30px;
      font-weight: bold;
      &__unit {
        font-size: 1.2rem;
        font-weight: normal;
      }
    }
  }
  &__users {
    margin-top: 20px;
  }
}
</style>
