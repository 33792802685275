import Vue from 'vue'
import VueAnalytics from 'vue-analytics'
import VueSanitize from 'vue-sanitize'
import App from '@/App.vue'
import '@/registerServiceWorker'
import router from '@/router'
import store from '@/store'
import vuetify from '@/plugins/vuetify'

/**
 * setting of vue sanitize
 * @see https://github.com/daichirata/vue-sanitize
 */
Vue.use(VueSanitize)

Vue.config.productionTip = false
Vue.config.devtools = !(process.env.NODE_ENV === 'production')

/**
 * setting of vue analytics
 */
Vue.use(VueAnalytics, {
  id: 'G-YMQ8RLP8ZP',
  router
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
